@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url(assets/KFOlCnqEu92Fr1MmSU5fBBc9.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url(assets/KFOmCnqEu92Fr1Mu4mxP.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url(assets/KFOlCnqEu92Fr1MmEU9fBBc9.ttf) format('truetype');
}
