/* You can add global styles to this file, and also import other style files */

@import "roboto.css";
@import "material-icons.css";

h1 {
    font-size: 1.8em;
}
h2 {
    font-size: 1.6em;
}

h3 {
    font-size: 1.3em;
}

h4 {
    font-size: 1.2em;
}

html,
body {
    height: 100%;
}

body {
    display: flex;
    flex-direction: column;
    margin: 0;
    font-family: Roboto, 'Helvetica Neue', sans-serif;
}
